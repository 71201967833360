import React, { useState } from "react";
import { Card, Divider, Select, Typography } from "antd";
import Layout from "../../../common/helper/layout";
import "./earnings.scss";
import { axiosInstance } from "../../../controller/axiosInterceptor";
import { useEffect } from "react";
import dayjs from "dayjs";

const { Title } = Typography;

export default function Earnings(props) {
  const currentYear = new Date().getFullYear();
  const [earning, setEarning] = useState([]);
  const [year, setYear] = useState([]);
  const [selectedYr, setSelectedYr] = useState(new Date().getFullYear());
  useEffect(() => {
    allEarnings();
  }, [selectedYr]);

  const allEarnings = async () => {
    try {
      const allEarnings = await axiosInstance.get(
        `business/earnings?year=${selectedYr}`
      );
      const startYear = new Date(
        allEarnings?.data?.output?.businessApprovedDate
      ).getFullYear();
      const arr = [];
      for (let year = startYear; year <= currentYear; year++) {
        arr.push(year);
      }
      if (arr[arr.length - 1] === currentYear) {
        setYear(arr);
      }
      setEarning(allEarnings?.data?.output);
    } catch (error) {
      console.log(error);
    }
  };
  const cardDetails = [
    {
      iconUrl: "/assets/icons/earnings-wallet.svg",
      count: `$ ${
        earning?.totalEarnings ? (earning?.totalEarnings).toFixed(2) : "0.00"
      }`,
      text: "Total Earnings",
    },
    {
      iconUrl: "/assets/icons/earnings-wallet.svg",
      count: `$ ${
        earning?.pendingEarnings
          ? Math.abs(earning?.pendingEarnings).toFixed(2)
          : "0.00"
      }`,
      text: "Pending Earnings",
    },
    {
      iconUrl: "/assets/icons/withdrawl.svg",
      count: `$ ${
        earning?.depositedEarnings
          ? (earning?.depositedEarnings).toFixed(2)
          : "0.00"
      }`,
      text: "Deposited Earnings",
    },
  ];

  return (
    <Layout>
      {/* <center>
        <h3 style={{ marginTop: "30vh" }}>This page is under Development</h3>
      </center> */}
      {earning && (
        <div className="my-earnings-container">
          <div className="row">
            {cardDetails.map((item, index) => {
              return (
                <div
                  key={index}
                  className="col-12 col-sm-12 col-md-12 col-xl-4 col-lg-4"
                >
                  <Card className="custom-dashboard-card row mx-0">
                    <div className="col-12">
                      <div className="card-icon-div">
                        <img
                          alt=""
                          src={item.iconUrl}
                          className="card-icon img-fluid"
                        />
                      </div>
                      <h4 style={{ marginLeft: "5%" }}>{item?.count}</h4>
                      <p style={{ marginLeft: "5%" }}>{item?.text}</p>
                    </div>
                  </Card>
                </div>
              );
            })}
          </div>
          <div className="txns-table-wrapper">
            <div className="d-flex justify-content-between align-items-center p-3">
              <h3 className="table-title">All Transactions</h3>
              <div className="d-flex align-items-center select-year-div">
                <p className="select-label">Select Year</p>
                <Select
                  suffixIcon={
                    <img
                      alt=""
                      src={"/assets/icons/select-dropdown-arrow.svg"}
                      className="img-fluid"
                      style={{ marginTop: "5px" }}
                    />
                  }
                  defaultValue={selectedYr}
                  onChange={(value) => {
                    setSelectedYr(value);
                  }}
                  className="year-dropdown"
                >
                  {year?.map((val) => {
                    return <Select.Option value={val}>{val}</Select.Option>;
                  })}
                </Select>
              </div>
            </div>
            <Divider className="m-0" />
            <div className="txns-table">
              {earning?.transaction?.list.length > 0 ? (
                earning?.transaction?.list.map((transaction, idx) => {
                  return (
                    <div className="d-flex justify-content-between align-items-center table-tr">
                      <div className="d-flex align-items-center table-td-1">
                        <div
                          style={{ backgroundColor: "#22C55E" }}
                          className="expense-icon-div"
                        >
                          <img
                            alt=""
                            src="/assets/icons/money-primary-icon.svg"
                            className="img-fluid"
                          />
                        </div>
                        <div className="expense-details-div">
                          <h6 className="expense-heading">
                            Income Deposited by {transaction?.payoutBy}
                          </h6>
                          <p className="expense-date mb-0">
                            {dayjs(new Date(transaction?.payoutDate)).format(
                              "MMMM D, YYYY"
                            )}
                          </p>
                        </div>
                      </div>
                      <p className="expense-price mb-0">
                        ${transaction?.payoutFee}
                      </p>
                    </div>
                  );
                })
              ) : (
                <center>
                  <h5 style={{ marginTop: "20vh" }}>No Transaction History</h5>
                </center>
              )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
