import { Button, Modal } from "antd";
import "./modals.scss";

export default function CloseAppointmentModal(props) {
  const { avalabilityDetails, acceptorDeclineHandler } = props;
  return (
    <Modal
      centered
      width={495}
      header={null}
      footer={null}
      open={props.closemodlvisible}
      onCancel={props.onClose}
      className="common-modal decline-modal close-appointment"
      closable
      closeIcon={
        <img alt={""} src={"/assets/icons/close-icon.svg"}  />
      }
    >
      <div className="row">
        <div className="col-12 crossImage common-div-modal text-center">
          <img
            alt={""}
            src={"/assets/icons/close-appointment-illustration.svg"}
          />
          <h3>Close Appointment</h3>
          <h6>
            {avalabilityDetails[0]?.userDetails?.firstName}{" "}
            {avalabilityDetails[0]?.userDetails?.lastName}
          </h6>
          <p>
            <img alt={""} src={"/assets/icons/map-pin.svg"} />
            {avalabilityDetails[0]?.userDetails?.address}{" "}
            {avalabilityDetails[0]?.userDetails?.city}{" "}
            {avalabilityDetails[0]?.userDetails?.province}{" "}
            {avalabilityDetails[0]?.userDetails?.postalCode}
          </p>
          <ul className="mb-0 list-inline">
            <li>
              <Button
                className="no-gradient button-common"
                onClick={(e) => {
                  acceptorDeclineHandler(
                    e,
                    avalabilityDetails[0]._id,
                    undefined,
                    undefined,
                    true
                  );
                  props.onClose()
                }}
              >
                Close
              </Button>
            </li>
            <li>
              <Button
                className="bg-transparent shadow-none confirm-logout-btn"
                onClick={props.onClose}
                style={{marginLeft:"-8px"}}
              >
                No
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  );
}
