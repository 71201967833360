import React, { useState } from "react";
import "./header-footer.scss";
import { Link } from "react-router-dom";
import TermsAndConditions from "../../modals/auths/TermsAndConditions";
import PrivacyPolicy from "../../modals/auths/PrivacyPolicy";
import Drawer from "../../drawer";
import TermsAndConditionsContent from "../../modals/auths/TermsAndConditionsContent";
import PrivacyPolicyContent from "../../modals/auths/PrivacyPolicyContent";

const CustomFooter = ({ contactRef, howRef, aboutRef }) => {
  const [visible, isVisible] = useState();
  const [visiblePrivacyPolicy, isVisiblePrivacyPolicy] = useState();

  const showTermsConditionModal = (visible) => {
    isVisible(visible);
  };
  const showPrivacyPolicyModal = (visible) => {
    isVisiblePrivacyPolicy(visible);
  };

  return (
    <div className="container-fluid footer-fluid">
      <div className="container">
        <div className="row footer-row-1">
          <div className="col-12 text-center">
            <Link className="logo-div-footer" to={""}>
              <img
                src="/assets/images/lma-logo-white-primary.png"
                alt=""
                className="img-fluid"
              />
            </Link>
            <ul className="mb-0">
              <li
                className="list-inline-item"
                onClick={() =>
                  aboutRef?.current?.scrollIntoView({ behaviour: "smooth" })
                }
              >
                <Link to={""}>About us</Link>
              </li>
              <li
                className="list-inline-item"
                onClick={() =>
                  howRef?.current?.scrollIntoView({ behaviour: "smooth" })
                }
              >
                <Link to={""}>How it works</Link>
              </li>
              <li
                className="list-inline-item"
                onClick={() =>
                  contactRef?.current?.scrollIntoView({ behaviour: "smooth" })
                }
              >
                <Link to={""}>Contact us</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row footer-row-2">
          <div className="col-12 col-sm-12 col-md-6 d-flex justify-content-start">
            <p className="mb-0">Copyright © 2024 </p>
            <p className="ms-2">
              <b>Last Minute App</b>
            </p>
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <ul className="list-inline mb-0 text-end">
              <li className="list-inline-item">
                <Link to={""} onClick={() => showTermsConditionModal(true)}>
                  Terms & Conditions
                </Link>
              </li>
              <li className="list-inline-item">
                <Link to={""} onClick={() => showPrivacyPolicyModal(true)}>
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Drawer show={visible} onClose={() => showTermsConditionModal(false)}>
        <TermsAndConditionsContent slider />
      </Drawer>
      <Drawer
        show={visiblePrivacyPolicy}
        onClose={() => showPrivacyPolicyModal(false)}
      >
        <PrivacyPolicyContent slider />
      </Drawer>
    </div>
  );
};

export default CustomFooter;
