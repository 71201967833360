import React, { useState } from "react";
import "./modal.scss";
import "./auths/auths.scss";
import { Button, Form, Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";
import ApproveAppointment from "../helper/modals/Approve";

const PaymentSuccessFul = (props) => {
  const navigate = useNavigate();
  const [approveModal, setApproveModal] = useState(false);
  const user = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  const onClose = () => {
    setApproveModal(false);
  };

  const onClick = () => {
    if (user?.isBusinessApproved == 'Approved') navigate("/business-provider/dashboard")
    else setApproveModal(true)
  }

  return (
    <>
      <Modal
        width="503px"
        className="common-modal auths-modal-small"
        centered
        title={false}
        open={props.visible}
        onOk={props.onClose}
        onCancel={props.onClose}
        footer={false}
        closeIcon={
          <img
            src="/assets/icons/close-icon-circle.svg"
            alt=""
            className="img-fluid"
          />
        }
      >
        <div className="row auths-main-row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 text-center auth-top-details">
                <Link to={"/"}>
                  <img
                    src="/assets/icons/payment-success.svg"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <h5>Payment Successful!</h5>
                <p>
                  Your payment is successful. Please wait while we redirect you to the dashboard.
                </p>
              </div>
              <div className="col-12 common-form-div">
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  autoComplete="off"
                >
                  <div className="row">
                    <div className="col-12 text-center">
                      <Form.Item className="mb-0">
                        <Button
                          className="common-btn submit-btn mt-0"
                          onClick={onClick}
                        >
                          Ok
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <ApproveAppointment
          visible={approveModal}
          visibleApproveAppointment={setApproveModal}
          onClose={onClose}
        />
      </Modal>
    </>
  );
};

export default PaymentSuccessFul;
