import React from "react";

const GetUnlimitedWork = () => {
  return (
    <svg
      width="31"
      height="38"
      viewBox="0 0 31 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="63bf2377218963dfc800cae3_icon-expense-reports-pastel-x-webflow-template.svg"
        clip-path="url(#clip0_548_15805)"
      >
        <path
          id="Vector"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.5 9.93305C0.5 4.84521 4.62451 0.720703 9.71234 0.720703H16.3395C18.4313 0.720703 20.4609 1.43264 22.0944 2.73942L26.5221 6.28156C28.7074 8.02981 29.9795 10.6767 29.9795 13.4752V28.3578C29.9795 33.4456 25.855 37.5701 20.7672 37.5701H9.71234C4.62451 37.5701 0.5 33.4456 0.5 28.3578V9.93305ZM17.0822 8.09058V4.40564L26.2946 11.7755H20.7672C18.732 11.7755 17.0822 10.1257 17.0822 8.09058ZM7.86987 20.9879C7.86987 19.9703 8.69477 19.1454 9.71234 19.1454H20.7672C21.7847 19.1454 22.6096 19.9703 22.6096 20.9879C22.6096 22.0055 21.7847 22.8304 20.7672 22.8304H9.71234C8.69477 22.8304 7.86987 22.0055 7.86987 20.9879ZM9.71234 26.5153C8.69477 26.5153 7.86987 27.3402 7.86987 28.3578C7.86987 29.3753 8.69477 30.2002 9.71234 30.2002H20.7672C21.7847 30.2002 22.6096 29.3753 22.6096 28.3578C22.6096 27.3402 21.7847 26.5153 20.7672 26.5153H9.71234Z"
          fill="#112047"
        />
      </g>
      <defs>
        <clipPath id="clip0_548_15805">
          <rect
            width="30"
            height="38"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GetUnlimitedWork;
