import dayjs from "dayjs";
import { axiosInstance } from "./controller/axiosInterceptor";

export const getUniqueDates = (dates) => {
  const list = dates.filter((item, index, self) => {
    return index === self.findIndex((t) => t.date === item.date);
  });

  return list;
}

export function generateNext90DatesFromPreviousWeek() {
  const today = dayjs()
  const previousWeek = add(today, 0, 'week'); // Get the date of the previous week
  const next90Dates = [];

  for (let i = 0; i <= 90; i++) {
    const nextDate = add(previousWeek, i, 'day');
    next90Dates.push(formatDate(nextDate, "YYYY-MM-DD")); // Format the date as 'YYYY-MM-DD'
  }
  return next90Dates;
}

export function generateNext90DatesFromSelectedDays(selectedday, dateselected, startDate) {
  const start = new Date(startDate ? startDate : dateselected);
  const startD = dayjs(start)

  const selected = new Date(dateselected);
  const selectedDate = dayjs(selected)

  const previousWeek = add(selectedDate, 0, 'week'); // Get the date of the previous week

  const next3Months = add(startD, 3, 'month');

  const next90Dates = [];

  const selectedDayIndices = selectedday.map(dayName => {
    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return dayNames.indexOf(dayName);
  });


  for (let i = 0; next90Dates.length < 90; i++) {
    const nextDate = add(previousWeek, i, 'day');

    const dayOfWeek = getDay(nextDate) // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    if (selectedDayIndices.includes(dayOfWeek) && formatDate(nextDate, "YYYY-MM-DD") <= formatDate(next3Months, "YYYY-MM-DD")) {
      next90Dates.push(formatDateISO(nextDate)); // Format the date as 'YYYY-MM-DD'
    }
    if (selectedday.length == 0) {
      next90Dates.push(formatDateISO(nextDate));
    }
    if (!selectedDayIndices.includes(dayOfWeek) && i === 0 && formatDate(nextDate, "YYYY-MM-DD") === formatDate(start, "YYYY-MM-DD")) {
      next90Dates.push(formatDateISO(nextDate));
    }
    if (nextDate > next3Months) break;
  }
  return next90Dates;

}

export async function getFinalselectedDates() {
  const response = await axiosInstance
    .post("/business/availability/list",)
    .then((res) => res)
    .catch((err) => err.data?.error?.message);
  return response
}

export const daysArr = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const getDay = (date) => dayjs(date).day();

export const formatDate = (date, format) => dayjs(date).format(format);

export const formatDateISO = (date) => dayjs(date).format("YYYY-MM-DDTHH:mm:ss");

export const getDate = (date) => dayjs(date).date();

export const add = (date, count, type) => date.add(count, type);

export const subtractDate = (date, count, type) => dayjs(date).subtract(count, type);

export const sortDates = (dates) => dates.sort((a, b) => new Date(a) - new Date(b));

export const formatLocalDate = (date) => {
  const dateArr = date.split("T")[0].split("-");
  return new Date(dateArr[0], dateArr[1] - 1, dateArr[2], 0, 0, 0, 0);
};

export const getFormattedList = (list) => {
  const updated = list.map((item) => {
    const date = dayjs(formatLocalDate(item.date)).format("YYYY-MM-DDTHH:mm:ss")
    const startTime = dayjs(item?.startTime.split('Z')[0]).format("YYYY-MM-DDTHH:mm:ss")
    const endTime = dayjs(item?.endTime.split('Z')[0]).format("YYYY-MM-DDTHH:mm:ss")

    return {
      ...item,
      date,
      startTime,
      endTime
    };
  })

  const uniqueList = getUniqueDates(updated);

  return uniqueList;
};