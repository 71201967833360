import React from 'react'
import TermsAndConditionsContent from '../../common/modals/auths/TermsAndConditionsContent'

const TermsAndConditionPage = () => {

  return (
    <div className='tc-privacy'>
      <TermsAndConditionsContent slider page />
    </div>
  )
}

export default TermsAndConditionPage