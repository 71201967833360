import React, { useEffect, useState } from "react";
import { Button, Upload, message } from "antd";
import axios from "axios";

const UploadImage = (props) => {
  const [imageUrl, setImageUrl] = useState("");

  const { IS_SERVICES, idx, handleUpdateServicesArr, currentImage, setLoader, handleFormChange } = props;
  const user = localStorage.getItem("accessToken");

  const handleChange = ({ file, fileList, event }) => {
  };

  useEffect(() => {
    setImageUrl(currentImage)
  }, [currentImage]);

  const handleUpload = (options) => {
    const { file } = options;

    // Define an array of allowed image MIME types
    const allowedImageTypes = ['image/jpg', 'image/png', 'image/jpeg'];

    // Check if the file type is in the allowed types array
    if (allowedImageTypes.includes(file.type)) {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("path", "profile");
      setLoader(true);
      handleFormChange && handleFormChange()

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/file/upload`, formData, {
          headers: {
            "content-type": "multipart/form-data",
            accessToken: user,
            Authorization: "Basic " + "M3JhZCQ5JSFAMyMhOjlyYSQ5JSFAMyEpJiMkQA==",
          },
        })
        .then((resp) => {
          setLoader(false);

          const s3Url = resp.data.output.imageUrl[0]?.location;
          setImageUrl(s3Url);
          if (IS_SERVICES) {
            handleUpdateServicesArr("imgUrl", s3Url, idx);
          } else {
            props?.form1Ref?.setFieldValue(props.fieldName, s3Url);
          }
        })
    } else {
      // Handle the case where the file type is not allowed (e.g., show an error message)
      message.error("Invalid file type. Only jpg,and png are allowed.")
    }
  };

  const uploadButton = (
    <div>
      <div>
        <img
          src={props?.userData?.profileImage ? "" : "/assets/icons/camera-dummy-img.svg"}
          alt=""
          className="img-fluid"
        />
      </div>
    </div>
  );

  return (
    <>
      <div className="upload-img-div w-100 position-relative">
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          onChange={handleChange}
          customRequest={handleUpload}
          rules={[
            {
              required: true,
              message: "Please input your business name!",
            },
          ]}
        >

          {props?.userData?.profileImage && !imageUrl && (
            <img
              src={props.userData?.profileImage}
              alt="avatar"
              type="image/svg+xml" 
              style={{
                aspectRatio: '1/1',
                height: 'inherit',
                borderRadius: '50%',
              }}
            />
          )}
          {imageUrl ? (
            <img
              src={imageUrl}
              type="image/svg+xml" 
              alt="avatar"
              style={{
                aspectRatio: '1/1',
                height: 'inherit',
                borderRadius: '50%',
              }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </div>
    </>
  );
};

export default UploadImage;
