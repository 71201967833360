export const handleRouting = (screen, navigate) => {
  const path = window.location.pathname;

  if (screen == 0 || screen == 1 || screen == 2 || screen == 3) {
    localStorage.setItem("currentTab", JSON.stringify(screen));
    // if user didn't complete bank detail and he did not subscribe
    navigate("/profile-details");
  } else if (screen == 4) {
    // if user didn't complete bank detail and he subscribed
    navigate("/availability");
  } else if (screen == 5) {
    // if user complete bank detail and he didn't subscribed
    if (!path.includes("services/top-listing"))
      navigate("/services/top-listing");
  } else if (screen == 6) {
    // if user complete bank detail and he didn't subscribed
    const paths = [
      "/settings",
      "/help",
      "/contact-us",
      "/services/top-listing/add-payments",
    ];

    if (!path.includes("business-provider")) {
      if (!paths.includes(path)) {
        navigate("/business-provider/dashboard");
      }
    }
  }
};
