import React, { useState } from "react";
import "./auths.scss";
import "../modal.scss";
import { Button, Form, Input, Modal, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import ResetPassword from "./ResetPassword";
import { axiosInstance } from "../../../controller/axiosInterceptor";
import SignUpModal from "./SignUpModal";
import EmailSentModal from "./EmailSentModal";
import Password from "antd/es/input/Password";
import ApproveAppointment from "../../helper/modals/Approve";
import Reject from "../../helper/modals/Reject"

const SignInModal = (props) => {
  const [visible, setVisible] = useState(false);
  const [emailsentModal, setemailsentModal] = useState();
  const [visibleResetPassword, setVisibleResetPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [approveModal, setApproveModal] = useState(false);
  const [rejectModal, setrejectModal] = useState(false);

  const [componentMounted, setComponentMounted] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const showResetPasswordModal = (visible) => {
    setVisibleResetPassword(visible);
  };

  // const handleBackToSign = () => {

  //   setVisibleResetPassword(false);

  //   props.setVisibleSignInModal(true);

  // };
  const handleSignUp = () => {
    // setVisible(true)
    form.resetFields();
    setEmail(""); // Reset email state
    setPassword(" ");
    props.onClose();
    props.openSignUpModal();
  };

  const saveNotification = (fcmToken) => {
    axiosInstance
      .post("/business/update/notification-status", { isNotification: true, fcmToken })
      .then((res) => {
        // console.log(res.data, 'notification');
      })
      .catch((err) => {
        // message.error(err.data?.error?.message);
      });
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    const params = {
      ...values,
      userType: 2,
    };
    axiosInstance
      .post("/business/signin", params)
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data.output));
        localStorage.setItem("accessToken", res.data.output?.accessToken);
        if (res.data.output?.fcmToken) saveNotification(res.data.output.fcmToken)

        if(res.data.output?.isBusinessApproved=="Rejected"){
          setrejectModal(true)
        }else if (
          res.data.output?.isBusinessApproved != "Approved" &&
          res.data.output?.isProfileUpdate
        ) {
          setApproveModal(true);
          localStorage.clear();
          setEmail("");
          setPassword("");
          form.setFieldValue("email", "");
          form.setFieldValue("password", "");
        } else {
          if (!res.data.output?.isProfileUpdate) {
            navigate("/profile-details");
          } else {
            navigate("/business-provider/dashboard");
          }
          if (!res.data.output?.isProfileUpdate) {
            navigate("/profile-details");
          } else if (!localStorage.getItem("user")) {
            navigate("/profile-details");
          } else {
            navigate("/business-provider/dashboard");
          }
          message.success("Login Successful");
          // window.location.reload();
        }
      })

      .catch((err) => {
        message.error(err.data?.error?.message);
      })

      .finally(() => {
        setIsLoading(false);
      });
  };
  const onClose = () => {
    setApproveModal(false);
  };
  const onCloseRejected=()=>{
    setrejectModal(false)
  }

  const areFieldsEmpty = email === "" || password === "";
  const closeHandler = (e) => {
    form.resetFields();
    setEmail(""); // Reset email state
    setPassword(" "); // Reset password state
  };

  return (
    <>
      <Modal
        width="62%"
        className="common-modal"
        centered
        title={false}
        open={props.visible}
        onOk={props.onClose}
        onCancel={props.onClose}
        footer={false}
        closeIcon={
          <img
            src="/assets/icons/close-icon-circle.svg"
            alt=""
            className="img-fluid"
            onClick={closeHandler}
          />
        }
      >
        <div className="row auths-main-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 auths-img-div">
            <div className="auth-img-card">
              <img
                src="/assets/images/banner-big-img.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 auths-details">
            <div className="row row align-items-center h-100" style={{marginTop:"3rem"}}>
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-11 col-lg-9 col-xl-9 mx-auto text-center auth-top-details">
                    <Link to={"/"}>
                      <img
                        src="/assets/images/logo-last-minute-app.png"
                        alt=""
                        className="img-fluid"
                      />
                    </Link>

                    <p>
                      Please enter your email address and create your profile
                    </p>
                  </div>

                  <div className="col-12 common-form-div">
                    <Form
                      autoComplete="off"
                      onFinish={handleSubmit}
                      initialValues={{ remember: true }}
                      form={form}
                    >
                      <div className="row">
                        <div className="col-12">
                          <Form.Item
                            className="position-relative"
                            label={false}
                            name="email"
                            rules={[
                              {
                                type: "email",

                                message: "Please enter a valid E-mail.",
                              },

                              {
                                required: true,

                                message: "Please input your email!",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter your email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              prefix={
                                <div className="icon-beige-div">
                                  <img
                                    src="/assets/icons/mail-icon-primary.svg"
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              }
                            />
                          </Form.Item>
                        </div>

                        <div className="col-12">
                          <Form.Item
                            className="position-relative"
                            label={false}
                            name="password"
                            rules={[
                              {
                                required: true,

                                message: "Please input your password!",
                              },
                            ]}
                          >
                            <Input.Password
                              iconRender={(visible) =>
                                visible ? (
                                  <img
                                    src="/assets/icons/eye-open.svg"
                                    alt=""
                                    className="img-fluid"
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <img
                                    src="/assets/icons/eye-closed.svg"
                                    alt=""
                                    className="img-fluid"
                                    style={{ cursor: "pointer" }}
                                  />
                                )
                              }
                              placeholder="Enter your password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              prefix={
                                <div className="icon-beige-div">
                                  <img
                                    src="/assets/icons/lock-icon-primary.svg"
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              }
                            />
                          </Form.Item>
                        </div>

                        <div className="col-12 forgot-password text-center">
                          <h6 style={{ marginBottom: "20px" }}>
                            <Link
                              onClick={() => showResetPasswordModal(true)}
                              to={""}
                            >
                              Forgot Password?
                            </Link>
                          </h6>
                        </div>

                        <div className="col-12 text-center">
                          <Form.Item className="mb-0">
                            <Button
                              htmlType="submit"
                              className="common-btn submit-btn mt-0"
                              loading={isLoading}
                              style={{
                                background: areFieldsEmpty
                                  ? "#B9CDD9"
                                  : "linear-gradient(135deg, #02e09e 0%, #66b6fa 100%)",
                              }}
                              disabled={areFieldsEmpty}
                            >
                              Sign In
                            </Button>
                          </Form.Item>
                        </div>

                        <div className="col-12 text-center bottom-text">
                          <p>
                            Don’t have an account?{" "}
                            <Link to={""} onClick={handleSignUp}>
                              Sign Up
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <ResetPassword
        visible={visibleResetPassword}
        visibleSignInModal={props.visibleSignInModal}
        changeVisible={(value) => setVisibleResetPassword(value)}
        onClose={() => showResetPasswordModal(false)}
        emailsentModal={emailsentModal}
      />

      <SignUpModal
        visible={visible}
        setVisible={setVisible}
        onClose={props.onClose}
      />

      <ApproveAppointment
        visible={approveModal}
        visibleApproveAppointment={setApproveModal}
        onClose={onClose}
      />
      <Reject
        rejectModal={rejectModal}
        visibleApproveAppointment={setrejectModal}
        onCloseRejected={onCloseRejected}
      />

      <EmailSentModal
        emailsentModal={emailsentModal}
        setemailsentModal={setemailsentModal}
        onClose={props.onClose}
      />
    </>
  );
};

export default SignInModal;
