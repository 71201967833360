import React from "react";
import Modal from "antd/es/modal/Modal";

import './index.scss';

const DeleteServicePopup = ({isOpen, setIsOpen, onConfirm, onCancel}) => {

    const onClickClose = () => {
        setIsOpen(false)
    }

    return (
        <Modal
            centered
            width={500}
            header={null}
            footer={null}
            open={isOpen}
            className="logoutmodal"
            closable={false}
            
        >
            <div className="app-container">
                <div onClick={onClickClose}>
                    <img src="/assets/icons/close-icon.svg" alt="close" className="close-icon" />
                </div>
                <div>
                    <img src="assets/images/decline-img.svg" alt="" className="logout-img" />
                </div>
                <div className="text-center">
                    <h3 className="heading">Are you sure you want
                        to delete this service?</h3>
                </div>
                <div>
                    <button type="button" className="no-btn" onClick={onCancel}>No</button>
                </div>
                <div>
                    <button type="button" className="yes-btn" onClick={onConfirm}>Yes</button>
                </div>
            </div>
        </Modal>
    );
};
export default DeleteServicePopup;