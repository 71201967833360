export const graphMonthData = [
  {
    name: "Jan",
    amount: 0,
  },
  {
    name: "Feb",
    amount: 0,
  },
  {
    name: "Mar",
    amount: 0,
  },
  {
    name: "Apr",
    amount: 0,
  },
  {
    name: "May",
    amount: 0,
  },
  {
    name: "Jun",
    amount: 0,
  },
  {
    name: "Jul",
    amount: 0,
  },
  {
    name: "Aug",
    amount: 0,
  },
  {
    name: "Sep",
    amount: 0,
  },
  {
    name: "Oct",
    amount: 0,
  },
  {
    name: "Nov",
    amount: 0,
  },
  {
    name: "Dec",
    amount: 0,
  },
];

export const graphDayData = [
  {
    name: 1,
    amount: 0,
  },
  {
    name: 2,
    amount: 0,
  },
  {
    name: 3,
    amount: 0,
  },
  {
    name: 4,
    amount: 0,
  },
  {
    name: 5,
    amount: 0,
  },
  {
    name: 6,
    amount: 0,
  },
  {
    name: 7,
    amount: 0,
  },
  {
    name: 8,
    amount: 0,
  },
  {
    name: 9,
    amount: 0,
  },
  {
    name: 10,
    amount: 0,
  },
  {
    name: 11,
    amount: 0,
  },
  {
    name: 12,
    amount: 0,
  },
  {
    name: 13,
    amount: 0,
  },
  {
    name: 14,
    amount: 0,
  },
  {
    name: 15,
    amount: 0,
  },
  {
    name: 16,
    amount: 0,
  },
  {
    name: 17,
    amount: 0,
  },
  {
    name: 18,
    amount: 0,
  },
  {
    name: 19,
    amount: 0,
  },
  {
    name: 20,
    amount: 0,
  },
  {
    name: 21,
    amount: 0,
  },
  {
    name: 22,
    amount: 0,
  },
  {
    name: 23,
    amount: 0,
  },
  {
    name: 24,
    amount: 0,
  },
  {
    name: 25,
    amount: 0,
  },
  {
    name: 26,
    amount: 0,
  },
  {
    name: 27,
    amount: 0,
  },
  {
    name: 28,
    amount: 0,
  },
  {
    name: 29,
    amount: 0,
  },
  {
    name: 30,
    amount: 0,
  },
  {
    name: 31,
    amount: 0,
  },
];

export const monthData = {
  1: 'Jan',
  2: 'Feb',
  3: 'Mar',
  4: 'Apr',
  5: 'May',
  6: 'Jun',
  7: 'Jul',
  8: 'Aug',
  9: 'Sep',
  10: 'Oct',
  11: 'Nov',
  12: 'Dec',
}

export const weekData = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
}