import React from "react";

const OurFeatures = () => {
  return (
    <>
      <div className="row">
        <div className="col-12 common-heading">
          <div className="heading-tag">Our Features</div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
          <div className="row mx-0 work-card-row">
            <div className="col-12">
              <div className="work-icon-div">
                <img
                  src="/assets/icons/img-dummy-icon.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <h5>Dynamic Availability</h5>
              <p>
                Tailor your availability effortlessly. Our intuitive platform
                lets service providers set flexible working hours, ensuring they
                can efficiently manage their time and meet customer demands.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
          <div className="row mx-0 work-card-row">
            <div className="col-12">
              <div className="work-icon-div">
                <img
                  src="/assets/icons/img-dummy-icon.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <h5>Seamless Payment Processing</h5>
              <p>
                Experience hassle-free transactions. Our secure payment system
                ensures that service providers receive compensation promptly
                upon completing a job, providing peace of mind and financial
                stability.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
          <div className="row mx-0 work-card-row">
            <div className="col-12">
              <div className="work-icon-div">
                <img
                  src="/assets/icons/img-dummy-icon.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <h5>Localized Service Requests</h5>
              <p>
                Capture opportunities in your vicinity. The platform connects
                service providers with local customers, maximizing efficiency
                and minimizing travel. Enjoy a steady flow of nearby service
                requests for increased business growth.
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
          <div className="row mx-0 work-card-row">
            <div className="col-12">
              <div className="work-icon-div">
                <img
                  src="/assets/icons/img-dummy-icon.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <h5>Build your reputation</h5>
              <p>
                Positive reviews boost credibility, attracting more customers
                and fostering a thriving service-oriented community. Build your
                reputation with each service.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurFeatures;
