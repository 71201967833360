import React from 'react';
import './notifications.scss'
import Layout from "../../../common/helper/layout";

const NotificationsMobile = () => {
    return (
        <>
            <Layout>
                <div className="row mx-0">
                    <div className="col-12 common-heading">
                        <h6>Notification</h6>
                    </div>
                </div>
                <div className="row notifications-row">
                    <div className="col-12">
                        <div className="row notification-row-main unread-message mx-0">
                            <div className="position-relative col-12">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </div>
                            <div className="position-relative text-end col-12">
                                <h6 className="mb-0">Today</h6>
                            </div>
                        </div>
                        <div className="row notification-row-main mx-0">
                            <div className="position-relative col-12">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </div>
                            <div className="position-relative text-end col-12">
                                <h6 className="mb-0">Today</h6>
                            </div>
                        </div>
                        <div className="row notification-row-main mx-0">
                            <div className="position-relative col-12">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </div>
                            <div className="position-relative text-end col-12">
                                <h6 className="mb-0">Today</h6>
                            </div>
                        </div>
                        <div className="row notification-row-main mx-0">
                            <div className="position-relative col-12">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </div>
                            <div className="position-relative text-end col-12">
                                <h6 className="mb-0">Today</h6>
                            </div>
                        </div>
                        <div className="row notification-row-main mx-0">
                            <div className="position-relative col-12">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                            </div>
                            <div className="position-relative text-end col-12">
                                <h6 className="mb-0">Today</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default NotificationsMobile;
