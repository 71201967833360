import React, { useEffect, useState, useRef } from "react";
import { Button, Checkbox, Col, Row } from "antd";
import "../profile-components/create-profile.scss";
import CustomMobileHeader from "../../../common/mobile/CustomMobileHeader";
import { axiosInstance } from "../../../controller/axiosInterceptor";
import { useNavigate, useLocation } from "react-router";
import Footer from "../../../common/helper/footer/footer";
import ApproveAppointment from "../../../common/helper/modals/Approve";

export default function AddServices(props) {
  const [serviceList, setServiceList] = useState([]);
  const [checkedServices, setCheckedServices] = useState([]);
  const [monthlyFee, setMonthlyFee] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, sethasMore] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const location = useLocation();
  const listInnerRef = useRef();
  const navigate = useNavigate();
  const skipPage = location.state?.fromPage || "";
  const user = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    getAddedServices();
  }, []);

  const getAddedServices = () => {
    const pagelimit = 10;
    axiosInstance
      .get(`business/listService/${page}/${pagelimit}`)
      .then((resp) => {
        setServiceList(resp.data.output.list);
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const onClose = () => {
    setApproveModal(false);
  };

  useEffect(() => {
    if (checkedServices?.length > 0) {
      let price = 0;
      const filteredServices = serviceList.filter((i) => {
        return checkedServices.some((n) => i._id === n);
      });
      if (filteredServices?.length > 0) {
        filteredServices.forEach((i) => {
          price = price + i?.price * 0.01;
        });
      }
      const serviceCharge = price * 0.01;
      setMonthlyFee(price);
      setDisabled(true);
      setServiceFee(serviceCharge);
    } else {
      setMonthlyFee(0);
      setDisabled(false);
      setServiceFee(0);
    }
  }, [checkedServices?.length]);

  const onScrollHandler = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      const addedScrollHeight = (scrollTop + clientHeight).toFixed(0);

      if (
        addedScrollHeight == scrollHeight ||
        addedScrollHeight == scrollHeight + 1 ||
        addedScrollHeight == scrollHeight - 1
      ) {
        if (hasMore) {
          setPage((prev) => prev + 1);
        }
      }
    }
  };

  return (
    <>
      <CustomMobileHeader />

      <div
        className={`container-fluid create-profile-main ${props.addnewClass ? "modal-fluid-main" : ""
          }`}
      >
        <div className="container">
          <div
            className={
              window.location.pathname === "/services/top-listing"
                ? "row create-profile-heading heading"
                : "row update-profile-heading heading"
            }
          >
            <div className="col-12 text-center">
              <h1>Add your services to top listing</h1>

              <p>Pay a monthly fee to opt a place in top 3 listing</p>
            </div>
          </div>

          <div
            className={
              window.location.pathname === "/services/top-listing"
                ? "row add-service-list-row"
                : "row edit-service-list-row"
            }
          >
            <div
              className={`col-12 col-sm-12 col-md-8 mx-auto ${props.addnewClass ? "col-md-12" : ""
                }`}
            >
              <div className="row mx-0 common-card-row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 text-center steps-form-heading">
                      <h5>Select Services</h5>
                    </div>
                  </div>

                  <div
                    className="row "
                    onScroll={onScrollHandler}
                    ref={listInnerRef}
                    style={{ height: "85px", overflow: "auto" }}
                  >
                    <div className="col-12 p-0 service-custom-checkbox">
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        onChange={(checkedValues) =>
                          setCheckedServices(checkedValues)
                        }
                      >
                        <div className="row w-100 m-0">
                          {serviceList.map((service) => {
                            return (
                              <div className="col-12 col-sm-12 col-md-6 px-2">
                                <Checkbox value={service._id}>
                                  <div className="row w-100 h-100 align-items-center px-3">
                                    <div className="col-12 d-flex align-items-center p-0">
                                      <div className="service-img-div">
                                        <img
                                          src={service.imgUrl}
                                          alt="service-image"
                                          className="img-fluid"
                                        />
                                      </div>

                                      <div className="service-details">
                                        {service.title}
                                      </div>
                                    </div>
                                  </div>
                                </Checkbox>
                              </div>
                            );
                          })}
                        </div>
                      </Checkbox.Group>
                    </div>
                  </div>

                  <div className="row amount-details-row">
                    <div className="col-12">
                      <ul className="list-inline">
                        <li className="list-inline-item text-start">
                          Monthly Fee
                        </li>

                        <li className="list-inline-item text-end">
                          $ {monthlyFee.toFixed(2)}
                        </li>
                      </ul>

                      <ul className="list-inline">
                        <li className="list-inline-item text-start">
                          Service Fee
                        </li>

                        <li className="list-inline-item text-end">
                          $ {serviceFee.toFixed(2)}
                        </li>
                      </ul>

                      <ul className="list-inline total-amount-ul">
                        <li className="list-inline-item text-start">
                          Total Amount
                        </li>

                        <li className="list-inline-item text-end">
                          ${" "}
                          {(Number(serviceFee) + Number(monthlyFee)).toFixed(2)}
                        </li>
                      </ul>
                    </div>

                    <div className="col-12 process-div text-center">
                      <Button
                        className={
                          !disabled
                            ? "disabledBtn  mx-auto auto "
                            : "common-btn-dis mx-auto auto"
                        }
                        onClick={() =>
                          navigate("/services/top-listing/add-payments", {
                            state: { checkedServices, monthlyFee, serviceFee, fromPage: skipPage },
                          })
                        }
                      >
                        Proceed to Pay
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {props.addnewClass ? (
            <div></div>
          ) : (
            <div className="steps-action steps-action-custom container">
              <div className="row mx-0 h-100 align-items-center justify-content-between">
                <Footer from />
                {window.location.pathname == '/services/top-listing' ? (
                  <div className="w-auto p-0">
                    <Button
                      className="skip-btn"
                      onClick={async () => {
                        if (user?.isBusinessApproved === 'Approved') {
                          await axiosInstance.put("/business/updateProfile", { screen: 6 });
                          localStorage.setItem("screen", 6);
                          navigate("/business-provider/dashboard")
                        }
                        else setApproveModal(true)
                      }}
                    >
                      Skip for Now
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <ApproveAppointment
        visible={approveModal}
        visibleApproveAppointment={setApproveModal}
        onClose={onClose}
      />
    </>
  );
}
