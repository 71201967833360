import { Button } from "antd";
import Layout from "../../../common/helper/layout";
import "./help-contact.scss";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function ContactUs() {
  const [loading, setLoading] = useState(false);

  const emailHandler = () => {
    const recipientEmail = "zach@appstudio.ca"; // Replace with the recipient's email address
    const emailSubject = "Contact Us -Last Minute App";
    const emailBody = "Hello, this is the pre-filled email body.";
    const mailtoLink = `mailto:${encodeURIComponent(
      recipientEmail
    )}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(
      emailBody
    )}`;
    // Open the default email client with the pre-filled email
    window.location.href = mailtoLink;
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 300);
    setLoading(false);
  });
  return (
    <Layout>
      {/* <center>
        <h3 style={{ marginTop: "30vh" }}>This page is under Development</h3>
      </center> */}
      <center>
        <div className="contact-us-container">
          <img
            src="/assets/icons/app-help-logo.jpg"
            alt=""
            className="img-fluid"
            style={{ width: "160px", objectfit: "contain", marginTop: "24px" }}
          />
          <div className="helper-text-wrapper">
            <Link
              to="mailto:info@lastminuteapplications.com"
              style={{ textDecoration: "none", color: "transparent" }}
            >
              <h1 className="email">info@lastminuteapplications.com</h1>
            </Link>
            <p className="helper-text">
              Please send your email by clicking on the <br />
              button below
            </p>
          </div>
          <Button className="email-us-btn common-btn" onClick={emailHandler}>
            Email Us
          </Button>
        </div>
      </center>
    </Layout>
  );
}
