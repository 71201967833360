import { Collapse, Input } from "antd";
import React, { useEffect, useState } from "react";
import Layout from "../../../common/helper/layout";
const { Panel } = Collapse;
export default function Help() {
  const prefix = (
    <img
      src="/assets/icons/search-icon.svg"
      width={35}
      alt="search icon"
      className="img-fluid align-items-baseline"
    />
  );
  const collapseIcon1 = (
    <img
      src="/assets/icons/plus-icon.svg"
      width={35}
      alt="collapse icon"
      className="img-fluid align-items-baseline"
    />
  );
  const collapseIcon2 = (
    <img
      src="/assets/icons/minus-icon.svg"
      width={35}
      alt="collapse icon"
      className="img-fluid align-items-baseline"
    />
  );
  const helpQuestion = [
    {
      question: "How do I manage my availability for appointments?",
      answer:
        "As a business, you can set your availability within the schedular every day. This ensures that customers can only book appointments during your operational hours.",
    },
    {
      question:
        "Question2 What happens if I receive multiple appointment requests for the same time slot?",
      answer:
        "If you have the capacity to serve multiple customers simultaneously, you can accept multiple appointment requests for the same time slot. However, if your capacity is limited, you may need to decline additional requests.",
    },
    {
      question:
        "Question3 Can customers book appointments outside of my available hours?",
      answer:
        "No, customers can only select dates and times within your specified operational hours. They won't be able to book appointments before or after.",
    },
    {
      question:
        "Question4 What if I have only one employee and receive multiple appointment requests?",
      answer:
        "If you have limited capacity, such as having only one employee available, you can only serve one user at a time. Additional appointment requests may need to be declined until your employee becomes available again.",
    },

    {
      question:
        "Question5 What if I decline an appointment request from a customer?",
      answer:
        " If you decline an appointment request, it means you're currently unable to accommodate that customer’s booking due to capacity constraints. Customers can try booking again at a different time slot if available.",
    },
    {
      question:
        "Question6 Can customers book appointments at the last minute? ",
      answer:
        "Customers can book appointments if there's availability within your operational hours.",
    },
    {
      question:
        "Question7 What if I need to modify my availability or capacity?",
      answer:
        "You can adjust your availability or capacity settings within the app's business dashboard. This allows you to adapt to changes in your schedule or staffing levels efficiently.",
    },
  ];

  const [activeKey, setActiveKey] = useState([]);
  const [filtered, setFiltered] = useState(helpQuestion);

  const handlePanelChange = (key) => {
    setActiveKey(key[key.length - 1]);
  };

  const onchnag = (value) => {
    const filteredQuestions = helpQuestion?.filter((item) => {
      // Check if the question contains the word "Lorem" (case-insensitive)setfirst
      setFiltered(item.question.toLowerCase().includes(value));
      return (
        item.question.toLowerCase().includes(value) ||
        item.answer.toLowerCase().includes(value)
      );
    });
    setFiltered(filteredQuestions);
  };

  return (
    <Layout>
      {/* <center>
            <h3 style={{marginTop:'30vh'}}>This page is under Development</h3>
    
    </center>             */}
      <div className="help-container">
        <div className="search-wrapper">
          <Input
            prefix={prefix}
            className="search-wrapper__input mb-3"
            placeholder="Search"
            onChange={(e) => {
              onchnag(e.target.value.toLowerCase());
            }}
          />
        </div>
        <div className="help-content-wrapper">
          <Collapse
            expandIconPosition="end"
            expandIcon={({ isActive }) =>
              isActive ? collapseIcon2 : collapseIcon1
            }
            activeKey={activeKey}
            onChange={handlePanelChange}
          >
            {filtered.length > 0 ? (
              filtered?.map((i, idx) => {
                return (
                  <Panel header={i.question} key={idx}>
                    <p className="panel-content">{i.answer}</p>
                  </Panel>
                );
              })
            ) : (
              <center>
                <h3 style={{ marginTop: "30vh" }}>No Data Found </h3>
              </center>
            )}
          </Collapse>
        </div>
      </div>
    </Layout>
  );
}
