import React from "react";

const GetWorkNearby = () => {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="63c824ab858f10fc71d5e016_icon-sales-and-business-pastel-x-webflow-template.svg"
        clip-path="url(#clip0_548_15802)"
      >
        <path
          id="Vector"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.0266 7.47183C10.892 3.2087 14.6611 0 19.1796 0C23.6981 0 27.4671 3.2087 28.3325 7.47183H28.5193C33.6776 7.47183 37.8591 11.6534 37.8591 16.8116V28.0193C37.8591 33.1776 33.6776 37.3591 28.5193 37.3591H9.83978C4.68156 37.3591 0.5 33.1776 0.5 28.0193V16.8116C0.5 11.6534 4.68156 7.47183 9.83978 7.47183H10.0266ZM13.8946 7.47183C14.6639 5.2953 16.7396 3.73591 19.1796 3.73591C21.6195 3.73591 23.6953 5.2953 24.4646 7.47183H13.8946Z"
          fill="#112047"
        />
      </g>
      <defs>
        <clipPath id="clip0_548_15802">
          <rect
            width="38"
            height="38"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GetWorkNearby;
