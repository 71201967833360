import React, { useEffect, useState, useRef } from "react";
import { Button, Dropdown, message } from "antd";
import { axiosInstance } from "../../../../controller/axiosInterceptor";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const [notification, setNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [notificationTotal, setNotificationsTotal] = useState();
  const [notificationUnRead, setNotificationUnRead] = useState();
  const [notDaY, setNotDay] = useState([]);
  const [loading, setLoading] = useState(false);
  const listInnerRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    allNotification();
    // Assuming currentDate is defined elsewhere in your component
  }, [page]);

  const allNotification = async () => {
    try {
      setLoading(true)
      const getnotification = await axiosInstance.get(
        `/business/notification-list?page=${page}&pageLimit=5`
      );
      setNotificationsTotal(getnotification?.data?.output?.resultCount);
      setNotificationUnRead(getnotification?.data?.output?.unreadCount);

      if (page !== (notification.length / 5)) {
        setNotifications([
          ...notification,
          ...getnotification?.data?.output?.list,
        ]);
        setNotDay([...notDaY, getnotification?.data?.output?.list.createdAt]);
        if (page === 1 && getnotification?.data?.output?.list?.length === 0) setNotifications([{}]);
      }
      setLoading(true)
    } catch (error) {
      setLoading(true)
    }
  };

  // scrolleffect
  const onScroll = () => {
    if (document.getElementsByClassName("ant-dropdown-menu")?.length) {
      const { scrollTop, scrollHeight, clientHeight } =
        document.getElementsByClassName("ant-dropdown-menu")[0];

      const addedScrollHeight = (scrollTop + clientHeight).toFixed(0);

      if (
        addedScrollHeight == scrollHeight ||
        addedScrollHeight == scrollHeight + 1 ||
        addedScrollHeight == scrollHeight - 1
      ) {
        if (notification.length <= 45 && notification.length < notificationTotal) {
          setPage((notification.length / 5) + 1);
        }
      }
    }
  };

  const readStatusHandler = async (id) => {
    const payload = {
      read: true,
      notificationId: id,
    };
    try {
      const notificationseen = await axiosInstance.post(
        "business/update/notificationReadStatus",
        payload
      );
      if (notificationseen?.data?.status) {
        allNotification();
        localStorage.setItem("Tab", "tmp_key-1");
      }
    } catch (error) { }
  };

  const daysFun = (date) => {
    const receivedDate = dayjs(date, { format: "YYYY-MM-DD" });
    const currentDate = dayjs();
    if (currentDate.isSame(receivedDate, "day")) {
      return "Today";
    } else if (currentDate.subtract(1, "day").isSame(receivedDate, "day")) {
      return "Yesterday";
    } else {
      return receivedDate.format("MMMM D, YYYY");
    }
  };

  const items = [
    ...notification.map((message) => {
      return {
        label: (
          <>
            {notification.length && notification[0]._id && <div
              className={
                !message?.read
                  ? "row notification-row-main unread-message mx-0"
                  : "row notification-row-main-no unread-message mx-0"
              }
              onClick={() => {
                readStatusHandler(message._id);
                onClickNotification(message?.data?.id)
              }}
            >
              <div className="position-relative col-12">
                <p>{message?.data?.description}</p>
              </div>
              <div className={"position-relative text-end col-12"}>
                <h6 className="mb-0">{daysFun(message?.createdAt)}</h6>
              </div>
            </div>}
            {!notification[0]._id && <div className="row text-center justify-content-center align-items-center mt-5 fw-normal fs-5">No Notifications</div>}
          </>
        ),
        key: "0",
      };
    }),
  ];

  const onClickNotification = (id) => {
    navigate(`/business-provider/appointments/appointment-details/${id}`, { state: { tabName: 'Pending' } });
    // navigate(`/business-provider/appointments-dashboard`, { state: { tabName: 'Pending' } });
  }

  return (
    <>
      <Dropdown
        menu={{
          items,
          itemRef: `${listInnerRef}`,
          onScroll,
        }}
        trigger={["click"]}
        overlayClassName="custom-dropdown custom-dropdown-notifications"
        onPopupScroll={onscroll}
        style={{ height: "300px" }}
        ref={listInnerRef}
      >
        <Button
          // onClick={allNotification}
          className="position-relative notification-btn bg-transparent border-0 p-0 shadow-none"
        >
          <img
            src="/assets/icons/bell-noti-icon.svg"
            alt=""
            className="img-fluid"
          />
          <span className="bedge-icon">{notificationUnRead}</span>
        </Button>
      </Dropdown>
    </>
  );
};

export default Notifications;
