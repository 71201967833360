import React, { useState, useEffect } from "react";
import { Input, Upload, Form, Button, message, List } from "antd";
import UploadImage from "../profile-components/steps/UploadImage";
import { axiosInstance } from "../../../controller/axiosInterceptor";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getAddressObject } from "../../../common/helper/getAddressObject";
import PhoneInput from "react-phone-input-2";
import SpinWrapper from "../../../common/Loader/SpinWrapper";
import axios from "axios";
import "react-phone-input-2/lib/style.css";

const EditProfile = (prop) => {
  const { userData, setUserData, onlyNumbersHandler } = prop;
  const [disabled, setDisabled] = useState(true);
  const [adress, setAddress] = useState();
  const [value, setValue] = useState("");
  const [showList, setShowList] = useState(false);
  const [form] = Form.useForm();
  const [phone, setphone] = useState("");
  const [loader, setLoader] = useState(true);
  const [businessImage, setBusinessImage] = useState("");
  const [businessDocument, setBusinessDocument] = useState("");
  const [countryCode, setCountryCode] = useState([]);

  useEffect(() => {
    if (userData.lastName) setLoader(false);
    if (userData.countryCode) setLoader(false);

    setBusinessImage(userData.businessDocument);
    setBusinessDocument(userData.businessDocument);
    // setphone(userData?.phoneNumber);
    setCountryCode(userData?.countryCode);
    setAddress({
      country: userData?.address?.country,
      address: userData?.address?.street,
      lat: userData.location?.coordinates[0],
      lon: userData.location?.coordinates[1],
    });
  }, [userData]);

  useEffect(() => {
    if (adress) {
      form.setFieldValue("city", adress?.city);
      form.setFieldValue("postalCode", adress?.postalCode);
      form.setFieldValue("province", adress?.province);
    }
  }, [adress?.city]);

  const uploadImageProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        //
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} File uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const initialValues = {
    firstName: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
    businessName: userData.businessName,
    phoneNumber: `${userData.countryCode} ${userData.phoneNumber}`,
    street: userData.address?.street,
    city: userData.address?.city,
    postalCode: userData.address?.postalCode,
    province: userData.address?.province,
    taxId: userData.taxId,
    document: userData.businessDocument,
    profileImage: userData.profileImage,
  };

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_API,
    options: {
      componentRestrictions: { country: ["ca", "USA", "IN"] },
    },
  });

  const getDetails = (placeid) => {
    const data = placePredictions.filter((item) => item.place_id === placeid);
    placesService?.getDetails(
      {
        placeId: data[0].place_id,
      },
      (placeDetails) => {
        const address = getAddressObject(placeDetails?.address_components);

        if (placeDetails?.name) setValue(placeDetails?.name);
        else
          setValue(
            `${address?.city} ${address?.region} ${address?.country} ${address?.postal_code}`
          );

        setAddress({
          address: placeDetails?.name,
          city: address?.city,
          province: address?.region,
          country: address?.country,
          postalCode: address?.postal_code,
          lat: placeDetails.geometry.location.lat().toString(),
          lon: placeDetails.geometry.location.lng().toString(),
        });

        form.setFieldsValue(
          { street: placeDetails?.name },
          { city: address?.city },
          { province: address?.region }
        );

        setShowList(false);
        const isFormValid = true;
        // Check form validity
        // const isFormValid = form.isValid && Object.keys(form.touched).some((field) => form.touched[field]);
        // Perform any actions based on form validity
        if (isFormValid) {
          // Form is valid, perform desired actions
          return form.isValid;
        } else {
          // Form is not valid, perform desired actions
          return form.isValid;
        }
      }
    );
  };

  const onlyTextHandler = (event) => {
    const charCode = event.which || event.keyCode;
    // Allow letters (A-Z and a-z) and spaces
    if (
      (charCode >= 65 && charCode <= 90) || // Uppercase letters
      (charCode >= 97 && charCode <= 122) || // Lowercase letters
      charCode === 32 // Space
    ) {
      // Allow the character
    } else {
      // Prevent any other characters
      event.preventDefault();
    }
  };

  const handleDocumentUpload = async (options) => {
    const { file } = options;
    try {
      const formData = new FormData();
      formData.append("image", file);
      setLoader(true);
      const token = localStorage.getItem("accessToken");

      const response = await axios
        .post(`${process.env.REACT_APP_BASE_URL}/file/upload`, formData,
          {
            headers: {
              "content-type": "multipart/form-data",
              accessToken: token,
              Authorization:
                "Basic " + "M3JhZCQ5JSFAMyMhOjlyYSQ5JSFAMyEpJiMkQA==",
            },
          }
        );
      if (response.data?.status == 200) {
        const s3Url = response.data.output?.imageUrl[0]?.location;
        setBusinessDocument(s3Url);
        setLoader(false);
        message.success(`${file.name} uploaded successfully`);
        setBusinessImage(file.name);
      }
      setDisabled(false);
    } catch (error) {
      message.error("File upload failed");
    }
  };

  const handleFormChange = () => {
    form.setFieldsValue(
      // { adress: adress?.address },
      { city: adress?.city },
      { province: adress?.region }
    );

    const hasErrors =
      !form.isFieldsTouched(true) ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabled(hasErrors);
  };

  const onFieldsChange = () => {
    const hasErrors =
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    // const hasValues = Object.keys(form.getFieldsValue()).values((item) => !item).length > 0;
    setDisabled(hasErrors);
  };

  const onFinish = () => {
    const values = form.getFieldsValue();

    if (adress?.country && (adress?.country != userData.address?.country)) {
      message.error("Please select same country address");
    }

    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      dob: userData.dob,
      profileImage: values.profileImage,
      businessName: values.businessName,
      phoneNumber: values.phone,
      countryCode,
      taxId: values.taxId,
      address: {
        city: values?.city,
        postalCode: values?.postalCode,
        province: values?.province,
        street: adress?.address,
        country: adress?.country,
      },
      location: {
        type: "Point",
        coordinates: [parseFloat(adress?.lat), parseFloat(adress?.lon)]
      },
      businessDocument: businessDocument,
    };
    axiosInstance
      .put("/business/updateProfile", payload)
      .then((res) => {
        setUserData(res.data.output);
        message.success("Your profile saved successfully");
      })
      .catch((err) => {
        message.error("Something went wrong..");
      });
  };

  const validateNumber = (_, value) => {
    if (!value || /^[0-9]+$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Please input only numbers"));
  };

  return (
    <SpinWrapper loader={loader}>
      <div className="row setting-card">
        <div className="heading-container">
          <h3 className="heading edit-services-heading">Edit Profile</h3>
        </div>

        <div className="col-12 common-form-div settings-form-div">
          {/* <center className='heading'>This page is under development</center> */}
          <p className="paragraph">Your first name and last name</p>
          {userData?.lastName && (
            <Form
              name="editProfile"
              initialValues={initialValues}
              autoComplete="off"
              form={form}
              onFieldsChange={onFieldsChange}
            >
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6">
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "*Please enter your last name",
                      },
                      {
                        pattern: new RegExp(
                          /^[a-zA-Z@~`!@#$%^&*()_=+\s'";:/?>.<,-]+$/
                        ),
                        message: "Field does not accept numbers",
                      },
                    ]}
                  >
                    <Input
                      className="input-field"
                      placeholder="First name"
                      onKeyPress={onlyTextHandler}
                      maxLength={15}
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "*Please enter your last name",
                      },
                      {
                        pattern: new RegExp(
                          /^[a-zA-Z@~`!@#$%^&*()_=+\s'";:/?>.<,-]+$/
                        ),
                        message: "Field does not accept numbers",
                      },
                    ]}
                  >
                    <Input
                      className="input-field"
                      placeholder="Last name"
                      onKeyPress={onlyTextHandler}
                      maxLength={15}
                    />
                  </Form.Item>
                </div>
                <div className="col-12">
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "*Please enter your email",
                      },
                      {
                        required: true,
                        type: "email",
                        message: "The input is not valid E-mail",
                      },
                    ]}
                  >
                    <Input
                      className="input-field email-input disabled-email"
                      placeholder="E-mail Id"
                      readOnly
                    />
                  </Form.Item>
                </div>
                <div className="col-12">
                  <h3 className="heading">Business Details</h3>
                </div>
                <div className="col-12">
                  <Form.Item name="profileImage">
                    <div className="file-upload">
                      <div className="file-upload-box1">
                        <UploadImage
                          {...uploadImageProps}
                          form1Ref={form}
                          currentImage={userData?.profileImage}
                          fieldName="profileImage"
                          userData={userData}
                          setLoader={setLoader}
                        />
                      </div>
                    </div>
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <Form.Item
                    name="businessName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your business name",
                      },
                      {
                        pattern: new RegExp(
                          /^[a-zA-Z0-9 ]+$/
                        ),
                        message: "Please enter valid business name",
                      },
                    ]}
                  >
                    <Input
                      className="input-field"
                      placeholder="Enter your business name"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your phone number",
                      },
                      {
                        validator: validateNumber,
                      },
                    ]}
                  >
                    <PhoneInput
                      country={"ca"}
                      value={countryCode}
                      className="ant-input"
                      onChange={(value, country, e, formattedValue) => {
                        setCountryCode(`+${country?.dialCode}`);
                        // setphone(value.slice(countryCode?.length-1));
                      }}
                      id="custommobile"
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <Form.Item
                    className="position-relative"
                    name="street"
                    label={false}
                    rules={[
                      {
                        required: true,
                        message: "Please input your street address!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      style={{ color: "black", height: "60px" }}
                      // suffix={<img src={symbolImg} />}
                      name="adress"
                      value={value}
                      autoFocus
                      onChange={(evt) => {
                        setValue(evt.target.value);
                        form?.setFieldValue("street", evt.target.value);
                        if (evt.target.value) getPlacePredictions({ input: evt.target.value });
                        if (!isPlacePredictionsLoading) setShowList(true);
                      }}
                      onKeyDown={(event) => {
                        if (event?.keyCode === 8 || event?.keyCode === 46) {
                          form?.setFieldValue("city", "");
                          form?.setFieldValue("province", "");
                          form?.setFieldValue("postalCode", "");
                          handleFormChange();
                        }
                      }}
                      loading={false}
                      placeholder="Enter address"
                    />
                    {value !== "" &&
                      showList &&
                      placePredictions.length > 0 && (
                        <div className="google-autosuggestion-list">
                          <List
                            dataSource={placePredictions}
                            renderItem={(item) => (
                              <>
                                <List.Item
                                  onClick={() => getDetails(item?.place_id)}
                                >
                                  <List.Item.Meta title={item.description} />
                                </List.Item>
                              </>
                            )}
                          />
                        </div>
                      )}
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <Form.Item
                    className="position-relative"
                    label={false}
                    name={"city"}
                    rules={[
                      {
                        required: true,
                        message: "Please input your city!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter your city"
                      onKeyPress={onlyTextHandler}
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <Form.Item
                    className="position-relative"
                    label={false}
                    name={"province"}
                    rules={[
                      {
                        required: true,
                        message: "Please input your province!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter your province"
                      onKeyPress={onlyTextHandler}
                    />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <Form.Item
                    className="position-relative"
                    label={false}
                    name="postalCode"
                    rules={[
                      {
                        required: true,
                        message: "Please input your postal code!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your postal code" />
                  </Form.Item>
                </div>
                <div className="col-12">
                  <Form.Item
                    className="position-relative"
                    label={false}
                    name="taxId"
                    rules={[
                      {
                        required: true,
                        message: "Please input your business tax id!",
                      },
                      {
                        pattern: new RegExp("^[A-Za-z0-9-]+$"),
                        message: "please enter a valid tax id",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      maxLength={9}
                      onKeyPress={onlyNumbersHandler}
                      placeholder="Enter tax id"
                    />
                  </Form.Item>
                </div>
                <div className="col-12">
                  <Form.Item label={false} name="document">
                    <Upload
                      fileList={[]}
                      customRequest={
                        businessImage ? () => { } : handleDocumentUpload
                      }
                    >
                      <Button>
                        {businessImage
                          ? businessImage.includes("com/")
                            ? businessImage.split("com/")[1]
                            : businessImage
                          : "Upload Incorporation Document"}
                        <div className="icon-beige-div upload-doc-icon">
                          {!businessImage ? (
                            <img
                              src="/assets/icons/upload-primary-icon.svg"
                              alt=""
                              className="img-fluid"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please input your incorporation document!",
                                },
                              ]}
                            />
                          ) : (
                            <img
                              alt={""}
                              src={"/assets/icons/cross.svg"}
                              onClick={() => {
                                setBusinessDocument("");
                                setBusinessImage("");
                              }}
                            />
                          )}
                        </div>
                      </Button>
                    </Upload>
                  </Form.Item>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
      <div className="btn-container">
        <Button
          type="primary"
          htmlType="submit"
          onClick={onFinish}
          className={disabled ? "common-btn steps-btn" : "common-btn-dis "}
          disabled={disabled}
          style={{ maxWidth: "150px" }}
        >
          Save
        </Button>
      </div>
    </SpinWrapper>
  );
};

export default EditProfile;
