import React, { useState } from "react";
import Navbar from "./navbar/navbar";
import Sidebar from "./sidebar/sidebar";
import "./layout.scss";
import Footer from "./footer/footer";
import CustomMobileHeader from "../mobile/CustomMobileHeader";

export default function Layout(props) {

  return (
    <div className="layout-container">
      <div className="container-fluid">
        <div className="main-fluid">
          <Sidebar />
          <div className="main-content">
            <Navbar />
            <div className="d-block d-sm-block d-md-none d-xl-none d-lg-none">
              <CustomMobileHeader />
            </div>
            {props.children}
            <Footer page="layout" />
          </div>
        </div>
      </div>
    </div>
  );
}
