import React, {useState} from 'react';
import {Link, NavLink} from "react-router-dom";
import {Button} from "antd";
import './mobile-header.scss'
import ModalComponent from "../helper/modals/ModalComponent";

const CustomMobileHeader = () => {
    const [isActive, setIsActive] = useState(false);
    const [openLogoutModal, setOpenLogoutModal] = useState(false);
    const handleToggleSidebar = () => {
        setIsActive((current) => !current);
    };
    return (
        <>
            <div
                className={`position-fixed w-100 sidebar-main sidebar-main-inner  ${isActive ? "sidebar-toggle" : ""}`}>
                <div className="row mx-0 align-items-center justify-content-between header-sidebar">
                    <div className="w-auto p-0">
                        <Link to="/">
                            <img src="/assets/icons/lma-white-logo.png" alt="" className="img-fluid"/>
                        </Link>
                    </div>
                    <div className="w-auto p-0">
                        <Button onClick={handleToggleSidebar} className="menu-hamburger-btn">
                            <img src="/assets/icons/cross-icon-primary.svg" alt="" className="img-fluid"/>
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ul className="mb-0 list-inline nav-list-ul nav-list-ul-inner-list">
                            <li>
                                <NavLink to={"/business-provider/dashboard"}>
                                    <span className="sidebar-icon">
                                        <img className="img-fluid gray-icon" src="/assets/icons/dashboard.svg" alt="dashboard"/>
                                        <img className="img-fluid white-icon" src="/assets/icons/dashboard-icon-white.svg" alt="dashboard"/>
                                    </span>
                                    <span className="sidebar-text">Dashboard</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/business-provider/appointments-dashboard"}>
                                    <span className="sidebar-icon">
                                        <img className="img-fluid gray-icon" src="/assets/icons/appointments.svg" alt="appointments"/>
                                        <img className="img-fluid white-icon" src="/assets/icons/appointments-icon-white.svg" alt="appointments"/>
                                    </span>
                                    <span className="sidebar-text">
                                        Appointments
                                    </span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/business-provider/earnings"}>
                                    <span className="sidebar-icon">
                                        <img className="img-fluid gray-icon" src="/assets/icons/total-earnings.svg" alt="earnings"/>
                                        <img className="img-fluid white-icon" src="/assets/icons/my-earnings-icon-white.svg" alt="earnings"/>
                                    </span>
                                    <span className="sidebar-text">
                                        My Earnings
                                    </span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/business-provider/availability"}>
                                    <span className="sidebar-icon">
                                        <img className="img-fluid gray-icon" src="/assets/icons/calendar-white.svg"
                                         alt="availability"/>
                                        <img className="img-fluid white-icon" src="/assets/icons/my-availablity-icon-white.svg" alt="availability"/>
                                    </span>
                                    <span className="sidebar-text">
                                        My Availability
                                    </span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/notifications"}>
                                    <span className="sidebar-icon">
                                        <img className="img-fluid gray-icon" src="/assets/icons/notification.svg" alt="notification"/>
                                        <img className="img-fluid white-icon" src="/assets/icons/my-earnings-icon-white.svg" alt="notification"/>
                                    </span>
                                    <span className="sidebar-text">Notification</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/settings"}>
                                    <span className="sidebar-icon">
                                        <img className="img-fluid gray-icon" src="/assets/icons/profile-sidebar.svg" alt="profile"/>
                                        <img className="img-fluid white-icon" src="/assets/icons/my-earnings-icon-white.svg" alt="profile"/>
                                    </span>
                                    <span className="sidebar-text">John Doe</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/help"}>
                                    <span className="sidebar-icon">
                                        <img className="img-fluid gray-icon" src="/assets/icons/help.svg" alt="help" />
                                        <img className="img-fluid white-icon" src="/assets/icons/help-icon-white.svg" alt="help"/>
                                    </span>
                                    <span className="sidebar-text">
                                        Help
                                    </span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/contact-us"}>
                                    <span className="sidebar-icon">
                                        <img className="img-fluid gray-icon" src="/assets/icons/contact-us.svg" alt="contact"/>
                                        <img className="img-fluid white-icon" src="/assets/icons/contact-us-icon-white.svg" alt="contact"/>
                                    </span>
                                    <span className="sidebar-text">
                                        Contact Us
                                    </span>
                                </NavLink>
                            </li>
                            <li className='bottom-logout'>
                                <NavLink to={" "} onClick={() => setOpenLogoutModal(true)}>
                                    <span className="sidebar-icon">
                                        <img className="img-fluid gray-icon" src="/assets/icons/logout.svg" alt="logout"/>
                                    </span>
                                    <span className="sidebar-text">
                                        Logout
                                    </span>
                                </NavLink>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <div className="mobile-header-custom">
                <div className="row mx-0 h-100 align-items-center justify-content-between">
                    <div className="w-auto">
                        <Link to="/">
                            <img src="/assets/icons/lma-white-logo.png" alt="" className="img-fluid"/>
                        </Link>
                    </div>
                    <div className="w-auto">
                        <Button onClick={handleToggleSidebar} className="menu-hamburger-btn">
                            <img src="/assets/icons/white-hamburger-menu.svg" alt="" className="img-fluid"/>
                        </Button>
                    </div>
                </div>
            </div>
            <ModalComponent
                heading="Are you sure you want to logout?"
                open={openLogoutModal}
                handleClose={() => setOpenLogoutModal(false)}
            />
        </>
    );
};

export default CustomMobileHeader;
