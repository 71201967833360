import React, { useState } from "react";
import "./auths.scss";
import "../modal.scss";
import { Button, Form, Input, Modal, message } from "antd";
import { Link } from "react-router-dom";
import EmailSentModal from "./EmailSentModal";
import SignInModal from "./SignInModal";
import { axiosInstance } from "../../../controller/axiosInterceptor";
const ResetPassword = (props) => {
  const [visible, setVisible] = useState(false);
  const [emailsentvisible, setemailsentvisibile] = useState(false);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const showMailModal = (visible) => {
    setemailsentvisibile(visible);
    setEmail("");
  };
  const [form] = Form.useForm();

  // const handleBackToSign = () => {
  //     changeVisible(true);
  //   };

  const isFieldEmpty = email === "";
  const handleSubmit = (values) => {
    setIsLoading(true);
    delete values.confirmPassword;
    const params = {
      ...values,
      userType: 2,
    };
    axiosInstance
      .post("/business/forgetPassword", params)
      .then((res) => {
        setVisible(visible);
        setemailsentvisibile(true);
        // localStorage.setItem('accessToken',res.data.output.accessToken)
        // navigate('/profile-details')
        // message.success("Signup Successful, Please login now..");
        // props.onClose();
      })
      .catch((err) => {
        message.error(err.data?.error?.message);
      });
  };
  const handleOnClose = () => {
    setemailsentvisibile(false);
  };
  const handleReset = () => {
    try {
      form.resetFields();
      setEmail("");
      props.changeVisible(false);
    } catch (error) {
      console.error("Error resetting fields:", error);
      props.changeVisible(false);
      setEmail("");
    }
  };
  return (
    <>
      <Modal
        width="550px"
        className="common-modal auths-modal-small"
        centered
        title={false}
        open={props.visible}
        onOk={props.onClose}
        onCancel={props.onClose}
        footer={false}
        closeIcon={
          <img
            src="/assets/icons/close-icon-circle.svg"
            alt=""
            className="img-fluid"
            onClick={handleReset}
          />
        }
      >
        <div className="row auths-main-row">
          <div className="col-12 auths-details auths-details-reset">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-11 mx-auto text-center auth-top-details">
                <Link to={"/"}>
                  <img
                    src="/assets/images/logo-last-minute-app.png"
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <h5>Reset your Password</h5>
                <p>
                  Enter the email address associated with your account and we
                  will send you the new password.
                </p>
              </div>
              <div className="col-12 common-form-div">
                <Form
                  name="basic"
                  initialValues={{ remember: true }}
                  autoComplete="off"
                  form={form}
                  onFinish={handleSubmit}
                >
                  <div className="row">
                    <div className="col-12">
                      <Form.Item
                        className="position-relative"
                        label={false}
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email!",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid E-mail.",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter your email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          prefix={
                            <div className="icon-beige-div">
                              <img
                                src="/assets/icons/mail-icon-primary.svg"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="col-12 text-center">
                      <Form.Item className="mb-0">
                        <Button
                          htmlType="submit"
                          className="common-btn submit-btn mt-0"
                          style={{
                            background: isFieldEmpty
                              ? "#B9CDD9 mt-3"
                              : "linear-gradient(135deg, #02e09e 0%, #66b6fa 100%) mt-3",
                          }}
                          disabled={isFieldEmpty}
                        >
                          Reset Password
                        </Button>
                      </Form.Item>
                    </div>
                    <div className="col-12 text-center bottom-text">
                      <p>
                        Back to{" "}
                        <Link to={""} onClick={handleReset}>
                          Sign In
                        </Link>
                      </p>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* <SignInModal handleBackToSign={handleBackToSign}/> */}
      {
        <EmailSentModal
          visible={emailsentvisible}
          onClose={handleOnClose}
          close={props.onClose}
          setemailsentvisibile={setemailsentvisibile}
          email={email}
        />
      }
    </>
  );
};

export default ResetPassword;
