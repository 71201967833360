import { Button, Form, Input } from 'antd'
import React from 'react'
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";

const StripeCardElements = ({ cardHoldersName, handleChange, handleSubmit, checkFields, stripeError, cardData }) => {
  return (
    <Form
      name="basic"
      autoComplete="off"
      onFinish={handleSubmit}
      className='card-element-wrapper'
    >
      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 mb-3">
          <Form.Item
            className="position-relative holder-name"
            label={false}
            name="name"
            rules={[
              {
                required: true,
                message: "Cardholder’s name!",
              },
            ]}
          >
            <Input
              placeholder={cardData?.cardNumber ? "XXXXXX" : "Cardholder’s name"}
              onChange={(e) =>
                handleChange(e, 'name')
              }
              value={cardHoldersName}
            />
          </Form.Item>
        </div>
        <div className="col-12 col-sm-12 col-md-6 mb-3">
          <Form.Item
            className="position-relative card-number-wrapper"
            label={false}
            name='cardNumber'
            rules={[
              {
                required: true,
                message: "Please input your Card Number!",
              },
            ]}
          >
            <CardNumberElement
              options={{
                showIcon: false,
                iconStyle: 'solid',
                placeholder: cardData?.cardNumber ? ` XXXX XXXX XXXX ${cardData.cardNumber}` : "Card Number",
                style: {
                  empty: {
                    '::placeholder': {
                      color: '#6B6B6B',
                    },
                  },


                  base: {
                    iconColor: '#111111',
                    color: '#111111',
                    fontWeight: '500',
                    fontSize: '15px',
                    height: '60px',
                    backgroundColor: '#f3f6f9',
                    fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                    fontSmoothing: 'antialiased',
                    ':-webkit-autofill': {
                      color: '#111111',
                    },
                  },
                  invalid: {
                    iconColor: 'red',
                    color: 'red',
                    fontWeight: '500',
                    fontSize: '16px',
                    fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                  },
                },
              }}
              className='card-number'
              onChange={(e) => handleChange(e, "cardnumber")}
            />
          </Form.Item>
          {stripeError?.cardNumber && <div className='error'>{stripeError?.cardNumber}</div>}
        </div>
        <div className="col-12 col-sm-12 col-md-6 mb-3">
          <Form.Item
            className="position-relative"
            label={false}
            name='cvv'
            rules={[
              {
                required: true,
                message: "Please input your Card Number!",
              },
            ]}
          >
            <CardCvcElement
              className='card-cvv'
              type="password"
              options={{
                placeholder: cardData?.cardNumber ? ` XXX` : "CVV",
                style: {
                  empty: {
                    '::placeholder': {
                      color: '#6B6B6B',
                    },
                  },
                  base: {
                    iconColor: '#111111',
                    color: '#111111',
                    fontWeight: '500',
                    fontSize: '16px',
                    fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                    fontSmoothing: 'antialiased',
                    ':-webkit-autofill': {
                      color: '#111111',
                    },
                  },
                  invalid: {
                    iconColor: 'red',
                    color: 'red',
                    fontWeight: '500',
                    fontSize: '16px',
                    fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                  },
                },
              }}
              onChange={(e) => handleChange(e, "cvv")}
            />
          </Form.Item>
          {stripeError?.cardCvc && <div className='error'>{stripeError?.cardCvc}</div>}
        </div>
        <div className="col-12 col-sm-12 col-md-6 mb-3">
          <Form.Item
            className="position-relative"
            label={false}
            name='date'
            rules={[
              {
                required: true,
                message: "Please input your Card Number!",
              },
            ]}
          >
            <CardExpiryElement
              className='card-expiry'
              options={{
                placeholder: cardData?.cardExpiry ? `${cardData?.cardExpiry}` : 'Expiry Date',
                style: {
                  empty: {
                    '::placeholder': {
                      color: '#6B6B6B',
                    },
                  },
                  base: {
                    iconColor: '#111111',
                    color: '#111111',
                    fontWeight: '500',
                    fontSize: '16px',
                    fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                    fontSmoothing: 'antialiased',
                    ':-webkit-autofill': {
                      color: '#111111',
                    },
                  },
                  invalid: {
                    iconColor: 'red',
                    color: 'red',
                    fontWeight: '500',
                    fontSize: '16px',
                    fontFamily: 'Poppins, Open Sans, Segoe UI, sans-serif',
                  },
                },
              }}
              onChange={(e) => handleChange(e, "expiry")}
            />
          </Form.Item>
          {stripeError?.cardExpiry && <div className='error'>{stripeError?.cardExpiry}</div>}
        </div>

        {window.location.pathname !== '/settings' && (<div className="col-12 process-div text-center">
          <Form.Item>
            <Button
              className="common-btn mx-auto"
              htmlType="submit"
              disabled={Object.values(checkFields)?.find((v) => v === false) === false ? true : false}
            >
              Continue
            </Button>
          </Form.Item>
        </div>)}
      </div>
    </Form>
  )
}

export default StripeCardElements