import React, { useState } from "react";
import "./auths.scss";
import "../modal.scss";
import { Button, Form, Input, Modal, Checkbox, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import { axiosInstance } from "../../../controller/axiosInterceptor";

const SignUpModal = (props) => {
  const [form] = Form.useForm();
  const [visible, isVisible] = useState();
  const [visiblePrivacyPolicy, isVisiblePrivacyPolicy] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [cPassword, setcPassword] = useState("");
  const navigate = useNavigate();

  const showTermsConditionModal = (visible) => {
    isVisible(visible);
  };

  const showPrivacyPolicyModal = (visible) => {
    isVisiblePrivacyPolicy(visible);
  };

  const handleSignIn = () => {
    form.resetFields();
    setEmail(""); // Reset email state
    setpassword(""); // Reset password state
    setcPassword("");
    props.onClose();
    props?.openSignInModal();
  };
  let regex = new RegExp(/^[A-Za-z0-9._]+@[a-z]+\.[a-z]{2,3}$/);
  const areFieldsEmpty =
    email === "" ||
    !regex.test(email) ||
    password === "" ||
    cPassword === "" ||
    password !== cPassword ||
    isChecked == false;

  const handleSubmit = (values) => {
    setIsLoading(true);
    delete values.confirmPassword;
    delete values.checkbox;
    const params = {
      ...values,
      userType: 2,
    };
    axiosInstance
      .post("/business/SignUp", params)
      .then((res) => {
        localStorage.clear()
        localStorage.setItem("accessToken", res.data.output.accessToken);
        setTimeout(() => {
          navigate("/profile-details");
        }, 300);

      })
      .catch((err) => {
        message.error(err.data?.error?.message);
      });
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const closeHandler = () => {
    form.resetFields();
    setEmail(""); // Reset email state
    setpassword(""); // Reset password state
    setcPassword("");
  };

  return (
    <>
      <Modal
        width="62%"
        className="common-modal auths-modal"
        centered
        title={false}
        open={props.visible}
        onOk={props.onClose}
        onCancel={props.onClose}
        footer={false}
        closeIcon={
          <img
            src="/assets/icons/close-icon-circle.svg"
            alt=""
            className="img-fluid"
            onClick={closeHandler}
          />
        }
      >
        <div className="row auths-main-row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 auths-img-div">
            <div className="auth-img-card">
              <img
                src="/assets/images/banner-big-img.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 auths-details">
            <div className="row align-items-center h-100">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 mx-auto text-center auth-top-details">
                    <Link to={"/"}>
                      <img
                        src="/assets/images/logo-last-minute-app.png"
                        alt=""
                        className="img-fluid"
                      />
                    </Link>
                    <p>
                      Please enter your email address and create your profile
                    </p>
                  </div>
                  <div className="col-12 common-form-div">
                    <Form
                      //   name="basic"
                      initialValues={{ remember: true }}
                      form={form}
                      autoComplete="off"
                      onFinish={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-12">
                          <Form.Item
                            className="position-relative"
                            label={false}
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: "Please input your email!",
                              },
                              {
                                type: 'email',
                                message: "Please enter a valid E-mail.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter your email"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              prefix={
                                <div className="icon-beige-div">
                                  <img
                                    src="/assets/icons/mail-icon-primary.svg"
                                    alt=""
                                    className="img-fluid"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className="col-12">
                          <Form.Item
                            className="position-relative"
                            label={false}
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: "Please input your password!",
                              },

                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const isContainsUppercase = /^(?=.*[A-Z]).*$/;
                                  const isContainsLowercase = /^(?=.*[a-z]).*$/;
                                  const isContainsNumber = /^(?=.*[0-9]).*$/;
                                  const isContainsSymbol =
                                    /^(?=.*[~`!@#$%^&*()--+={}|\\:;"'<>,.?/_₹]).*$/;
                                  if (value?.length < 8) {
                                    return Promise.reject(
                                      new Error(
                                        "Must contain minimum of 8 characters."
                                      )
                                    );
                                  }
                                  if (value?.length > 12) {
                                    return Promise.reject(
                                      new Error(
                                        "Must contain less than 12 characters."
                                      )
                                    );
                                  }
                                  if (
                                    !isContainsNumber.test(value) ||
                                    !isContainsLowercase.test(value) ||
                                    !isContainsSymbol.test(value) ||
                                    !isContainsUppercase.test(value)
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        "Atleast one uppercase, lowercase, number(0-9), special character."
                                      )
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Input.Password
                              value={password}
                              onChange={(e) => {
                                setpassword(e.target.value);
                              }}
                              iconRender={(visible) =>
                                visible ? (
                                  <img
                                    src="/assets/icons/eye-open.svg"
                                    alt=""
                                    className="img-fluid"
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <img
                                    src="/assets/icons/eye-closed.svg"
                                    alt=""
                                    className="img-fluid"
                                    style={{ cursor: "pointer" }}
                                  />
                                )
                              }
                              placeholder="Enter your password"
                              maxLength={12}
                              prefix={
                                <div className="icon-beige-div">
                                  <img
                                    src="/assets/icons/lock-icon-primary.svg"
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className="col-12">
                          <Form.Item
                            className="position-relative"
                            label={false}
                            name="confirmPassword"
                            rules={[
                              {
                                required: true,
                                message: "Please input your password!",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue("password") === value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "Password should match with confirm password"
                                    )
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input.Password
                              value={cPassword}
                              onChange={(e) => {
                                setcPassword(e.target.value);
                              }}
                              iconRender={(visible) =>
                                visible ? (
                                  <img
                                    src="/assets/icons/eye-open.svg"
                                    alt=""
                                    className="img-fluid"
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <img
                                    src="/assets/icons/eye-closed.svg"
                                    alt=""
                                    className="img-fluid"
                                    style={{ cursor: "pointer" }}
                                  />
                                )
                              }
                              placeholder="Enter your password"
                              maxLength={12}
                              prefix={
                                <div className="icon-beige-div">
                                  <img
                                    src="/assets/icons/lock-icon-primary.svg"
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                              }
                            />
                          </Form.Item>
                        </div>
                        <div className="col-12">
                          <Form.Item label={false} name="checkbox">
                            <div className="d-flex w-100">
                              <Checkbox
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                              />
                              <div className="d-inline-block check-box-div">
                                By tapping here you agree to our{" "}
                                <Link
                                  onClick={() => showTermsConditionModal(true)}
                                  to={""}
                                >
                                  Terms and Conditions
                                </Link>{" "}
                                &{" "}
                                <Link
                                  onClick={() => showPrivacyPolicyModal(true)}
                                  to={""}
                                >
                                  Privacy Policy
                                </Link>
                                .
                              </div>
                            </div>
                          </Form.Item>
                        </div>
                        <div className="col-12 text-center">
                          <Form.Item className="mb-0">
                            <Button
                              htmlType="submit"
                              className="common-btn submit-btn mt-0"
                              style={{
                                background: areFieldsEmpty
                                  ? "#B9CDD9"
                                  : "linear-gradient(135deg, #02e09e 0%, #66b6fa 100%)",
                              }}
                              disabled={areFieldsEmpty}
                            >
                              Sign Up
                            </Button>
                          </Form.Item>
                        </div>
                        <div className="col-12 text-center bottom-text">
                          <p>
                            Already have an account?{" "}
                            <Link to={""} onClick={handleSignIn}>
                              Sign In
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <TermsAndConditions
        visible={visible}
        onClose={() => showTermsConditionModal(false)}
      />
      <PrivacyPolicy
        visible={visiblePrivacyPolicy}
        onClose={() => {
          showPrivacyPolicyModal(false);
        }}
      />
    </>
  );
};

export default SignUpModal;
