import Tabs from "antd/es/tabs";
import { React, useEffect, useState, useRef } from "react";
import { message } from "antd";
import { axiosInstance } from "../../../controller/axiosInterceptor";
import EditProfile from "./EditProfile";
import EditServices from "./EditServices";
import ChangePassword from "./ChangePassword";
import UpdatePaymentInformation from "./UpdatePaymentInformation";
import Layout from "../../../common/helper/layout";
import { useSearchParams } from "react-router-dom";
import "./settings.scss";
import RatingsComp from "../../../common/landing/Ratings";
import DeleteAccountModal from "../../../common/helper/modals/DeleteAccountModal";

const Settings = ({ businessData }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [serviceDetails, setServiceDetails] = useState();
  const [page, setPage] = useState(1);
  const [hasMore, sethasMore] = useState(false);

  const id = searchParams.get("id");

  const [activeKey, setActiveKey] = useState(`${id}`);
  const [userData, setUserData] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    setUserData(businessData);
  }, [businessData]);

  useEffect(() => {
    getServicedData();
  }, [page]);

  useEffect(() => {
    setActiveKey(`${id}`);
  }, [id]);

  const getServicedData = () => {
    const pagelimit = 10;
    axiosInstance
      .get(`business/listService/${page}/${pagelimit}`)
      .then((res) => {
        setServiceDetails(res.data.output.list);
        sethasMore(res.data.output.hasResult);
      })
      .catch((err) => {
        message.error(err.data?.error?.message);
      });
  };

  const onlyTextHandler = (event) => {
    const charCode = event.which || event.keyCode;
    // Allow letters (A-Z and a-z) and spaces
    if (
      (charCode >= 65 && charCode <= 90) || // Uppercase letters
      (charCode >= 97 && charCode <= 122) || // Lowercase letters
      charCode === 32 // Space
    ) {
      // Allow the character
    } else {
      // Prevent any other characters
      event.preventDefault();
    }
  };

  const tabsArr = [
    {
      icon: (
        <img
          src={
            activeKey == 1
              ? "/assets/icons/EditProfileIcon.svg"
              : "/assets/icons/UA_EditProfile.svg"
          }
          alt="edit-profile"
        />
      ),
      name: "Edit Profile",
      key: "1",
      component: (
        <EditProfile
          setUserData={setUserData}
          userData={userData}
          onlyTextHandler={onlyTextHandler}
        />
      ),
    },

    {
      icon: (
        <img
          src={
            activeKey == 2
              ? "/assets/icons/UA_EditServices.svg"
              : "/assets/icons/EditServicesIcon.svg"
          }
          alt="edit-services"
        />
      ),
      name: "Edit Services",
      key: "2",
      component: (
        <EditServices
          serviceDetails={serviceDetails}
          onlyTextHandler={onlyTextHandler}
          setPage={setPage}
          hasMore={hasMore}
          getServicedData={getServicedData}
        />
      ),
    },

    {
      icon: (
        <img
          src={
            activeKey == 3
              ? "/assets/icons/UA_ChangePassword.svg"
              : "/assets/icons/ChangePasswordIcon.svg"
          }
          alt="change-password"
        />
      ),
      name: "Change Password",
      key: "3",
      component: <ChangePassword />,
    },
    {
      icon: (
        <img
          src={
            activeKey == 4
              ? "/assets/icons/UA_UpdatePaymentInfo.svg"
              : "/assets/icons/UpdatePaymentInfoIcon.svg"
          }
          alt="update-profile-info"
        />
      ),
      name: "Update Payment Information",
      key: "4",
      component: (
        <UpdatePaymentInformation
          address={userData?.address}
          bankDetails={userData?.bankDetails}
          debitCardDetails={userData?.debitCardDetails}
        />
      ),
    },
    {
      icon: (
        <img
          src={
            activeKey == 5
              ? "/assets/icons/rating-large.svg"
              : "/assets/icons/rating-small.svg"
          }
          alt="update-profile-info"
          style={{ width: "50px", height: "50px" }}
        />
      ),
      name: "Business Ratings",
      key: "5",
      component: <RatingsComp />,
    },
    // {
    //   icon: (
    //     <img
    //       src={
    //         activeKey == 6
    //           ? "/assets/icons/delete-account.svg"
    //           : "/assets/icons/delete-account.svg"
    //       }
    //       alt="delete"
    //       style={{ width: "50px", height: "50px" }}
    //     />
    //   ),
    //   name: "Delete Account",
    //   key: "6",
    //   component: <></>,
    // },
  ];

  return (
    <>
      <Layout>
        <div className="settings">
          <Tabs
            activeKey={activeKey}
            tabPosition="left"
            size="large"
            onChange={(e) => {
              if (e == 6) {
                setOpenDeleteModal(true);
              } else {
                setActiveKey(e);
                setSearchParams({ id: e });
              }
            }}
          >
            {tabsArr.map((item) => {
              return (
                <Tabs.TabPane
                  key={item.key}
                  className="tab"
                  tab={
                    <>
                      <span className="imageicons">{item.icon}</span>
                      <span className="settingnames">{item.name}</span>
                    </>
                  }
                >
                  {item.component}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </div>
        <DeleteAccountModal
          open={openDeleteModal}
          handleClose={() => setOpenDeleteModal(false)}
        />
      </Layout>
    </>
  );
};

export default Settings;
