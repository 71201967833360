import React from "react";
import { Modal } from "antd";
import TermsAndConditionsContent from "./TermsAndConditionsContent";
import "./auths.scss";
import "../modal.scss";

const TermsAndConditions = (props) => {
  return (
    <Modal
      width="62%"
      className="common-modal common-modal-tc"
      centered
      title={false}
      open={props.visible}
      onOk={props.onClose}
      onCancel={props.onClose}
      footer={""}
      closeIcon={
        <img
          src="/assets/icons/close-icon-circle.svg"
          alt=""
          className="img-fluid"
        />
      }
    >
      <TermsAndConditionsContent />
    </Modal>
  );
};

export default TermsAndConditions;
